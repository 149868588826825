import { IMY24CTA } from '@/components/sections/my24/shared/cta';
import {
  MENU_LCV_PRIVILEGES_MY_ISUZU,
  MENU_LCV_SERVICE_CENTER,
  MENU_LCV_SERVICE_PARTS,
  MENU_LCV_SPECIAL_OFFER_PPV,
  MENU_LCV_SPECIAL_OFFER_PUP,
} from '@/contents/lcv/link';
import { IItem } from '@/types/shared';

export const getCta = (
  page:
    | 'home'
    | 'v-cross'
    | 'x-series'
    | 'mu-x'
    | 'pickup-2-door'
    | 'pickup-4-door'
    | 'spark'
) => {
  switch (page) {
    case 'v-cross':
      return getProductCta(
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6086e8b8b14bd693/650a549aff69780bd71c4122/v-cross.jpg',
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt697f8d43d3fc83db/650a54b7b556bd7e2fd3b6d4/v-cross.jpg',
        MENU_LCV_SPECIAL_OFFER_PUP.url + '/v-cross-4-door-financial',
        MENU_LCV_SPECIAL_OFFER_PUP.target
      );
    case 'pickup-4-door':
      return getProductCta(
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt50396d0288863d76/650a5403c5537cf12c4d034c/4-door.jpg',
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt07b9a569da35dd53/650a52cab29ddbdf60544d62/4-door.jpg',
        MENU_LCV_SPECIAL_OFFER_PUP.url + '/hilander-4-doors-financial',
        MENU_LCV_SPECIAL_OFFER_PUP.target
      );
    case 'pickup-2-door':
      return getProductCta(
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfc80906da208b0d6/651fb86662df4415576dc3c6/2-door.jpg',
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta0a5cee339d2ba81/651fb891705ef39e5348b5bd/2-door.jpg',
        MENU_LCV_SPECIAL_OFFER_PUP.url + '/spacecab-s-financial',
        MENU_LCV_SPECIAL_OFFER_PUP.target
      );
    case 'spark':
      return getProductCta(
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd7dadc5cd4a0b21b/650a544b7e03024b28bbe95b/spark.jpg',
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7d9d47398075cb40/650a53bef0b16bdb0e1d9253/spark.jpg',
        MENU_LCV_SPECIAL_OFFER_PUP.url + '/spark-1-9-financial',
        MENU_LCV_SPECIAL_OFFER_PUP.target
      );
    case 'x-series':
      return getProductCta(
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt029b12608cbed6de/65a89e9dcfd284a445f1c38a/x-series.jpg',
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9b661811bc8b97af/65a89e39d48d3d00d8cec67b/x-series-desktop.jpg',
        MENU_LCV_SPECIAL_OFFER_PUP.url + '/x-series-speed-financial',
        MENU_LCV_SPECIAL_OFFER_PUP.target
      );
    case 'mu-x':
      return getProductCta(
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt832b70aa7c76eff7/66626f1de85565a72f2b7450/rs.jpg',
        'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2f6189c1c0b4aef2/66626f1d491b8151fe503bc1/rs-desktop.jpg',
        MENU_LCV_SPECIAL_OFFER_PPV.url + '/mu-x-financial',
        MENU_LCV_SPECIAL_OFFER_PPV.target
      );
    default:
      return getCtaDefault();
  }
};

const myIsuzuCta: IItem = {
  preTitle: 'my-ISUZU',
  title: 'มาย-อีซูซุ',
  description:
    'แอปพลิเคชันสำหรับเจ้าของรถอีซูซุ <span>ที่จะทำให้คุณไม่พลาด</span>กับทุกสิทธิประโยชน์',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt18904350fe8c61bf/651e8447ecf48b5748c38b73/cta-my-i-mobile.jpg',
    alt: 'my-ISUZU',
    width: 1080,
    height: 608,
  },
  imageDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt520aa0046041fd5e/651e849af156d52b0baf1b74/cta-my-i-desktop.jpg',
    alt: 'my-ISUZU',
    width: 1080,
    height: 1440,
  },
  buttons: [
    {
      label: 'ดูรายละเอียด',
      url: MENU_LCV_PRIVILEGES_MY_ISUZU.url,
      target: MENU_LCV_PRIVILEGES_MY_ISUZU.target,
      icon: 'arrow-forward',
      isMinWidth: true,
      color: 'button-white',
    },
  ],
};

const serviceCenterCta: IItem = {
  preTitle: 'Service Center',
  title: 'ศูนย์บริการอีซูซุ',
  description:
    'อุ่นใจด้วยมาตรฐานการดูแลของอีซูซุ <span>ปลอดภัยทั้งรถและคุณ</span>',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfb6c14fc1852acdd/6502f1879bcd1b05911d0471/showroom.jpg',
    alt: 'Service Center',
    width: 1080,
    height: 608,
  },
  imageDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt64f07376537356c8/6502f179a8cf8b28a43e0d41/showroom.jpg',
    alt: 'Service Center',
    width: 1080,
    height: 1440,
  },
  buttons: [
    {
      label: 'ดูรายละเอียด',
      url: MENU_LCV_SERVICE_CENTER.url,
      target: MENU_LCV_SERVICE_CENTER.target,
      icon: 'arrow-forward',
      isMinWidth: true,
      color: 'button-white',
    },
  ],
};

const genuinePartsCta: IItem = {
  preTitle: 'TRI PETCH Genuine Parts',
  title: 'อะไหล่แท้ตรีเพชร',
  description: 'คุณภาพที่คุณมั่นใจ',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9c317909e8fb9119/6502f187e60bfc806ad3f1d1/parts.jpg',
    alt: 'TRI PETCH Genuine Parts',
    width: 1080,
    height: 608,
  },
  imageDesktop: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd7f051c50e62f6a4/6502f17a0433c00338254862/parts.jpg',
    alt: 'TRI PETCH Genuine Parts',
    width: 1080,
    height: 1440,
  },
  buttons: [
    {
      label: 'ดูรายละเอียด',
      url: MENU_LCV_SERVICE_PARTS.url,
      target: MENU_LCV_SERVICE_PARTS.target,
      icon: 'arrow-forward',
      isMinWidth: true,
      color: 'button-white',
    },
  ],
};

const getCtaDefault = (): IMY24CTA => ({
  active: true,
  headline: {
    title: 'CTA',
  },
  items: [myIsuzuCta, serviceCenterCta, genuinePartsCta],
  dataTrack: 'lcv-home',
});

const CTA_TITLE = 'CTA';
const SPECIAL_OFFER_TITLE = 'Special Offer';

const getProductCta = (
  specialOfferImage?: string,
  specialOfferImageDesktop?: string,
  specialOfferUrl?: string,
  specialOfferTarget?: '_self' | '_blank' | '_parent' | '_top'
): IMY24CTA => ({
  active: true,
  headline: {
    title: CTA_TITLE,
  },
  items: [
    {
      preTitle: SPECIAL_OFFER_TITLE,
      title: 'ข้อเสนอพิเศษ',
      description: 'โปรโมชันล่าสุดสำหรับรถปิกอัพและรถอเนกประสงค์',
      image: {
        src: specialOfferImage,
        alt: SPECIAL_OFFER_TITLE,
        width: 1080,
        height: 608,
      },
      imageDesktop: {
        src: specialOfferImageDesktop,
        alt: SPECIAL_OFFER_TITLE,
        width: 1080,
        height: 1440,
      },
      buttons: [
        {
          label: 'ดูรายละเอียด',
          url: specialOfferUrl,
          target: specialOfferTarget,
          icon: 'arrow-forward',
          isMinWidth: true,
          color: 'button-white',
        },
      ],
    },
    serviceCenterCta,
    genuinePartsCta,
  ],
  dataTrack: 'lcv-product',
  theme: 'dark',
});
