import {
  MUX_DATA,
  PICKUP_2DOOR_DATA,
  PICKUP_4DOOR_DATA,
  SPARK_DATA,
  V_CROSS_DATA,
  X_SERIES_DATA,
} from '@/contents/lcv/product/cafe/lineup';
import { IMy24LineupItem, IMy24Model } from '@/types/shared';

export const getModelLineupId = (model: IMy24Model) => {
  const MODEL_LINE_UP_ID = {
    'mu-x': 1,
    'v-cross': 2,
    '4-door': 3,
    '2-door': 4,
    spark: 5,
    'x-series': 6,
  };
  return MODEL_LINE_UP_ID[model] || 1;
};

export const HOME_LINEUP_DATA: IMy24LineupItem[] = [
  {
    preTitle: 'MU-X',
    title: 'มิว-เอ็กซ์',
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt95d5d1008250eead/66692ca146a0375d52a26414/mu-x-front-rs-eiger-grey.png',
      alt: MUX_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd2255779dd00e168/665996b75311e3c53bce84a8/mu-x-lineup-bg.jpg',
      alt: MUX_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc36c2913b3c62ba7/665996b744be248c94e20689/mu-x-lineup-bg-desktop.jpg',
      alt: MUX_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: '/isuzu-mu-x',
      color: 'button-gray',
      isMinWidth: true,
    },
    price: MUX_DATA.price,
  },
  {
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltab16cade4bd5daee/651f7880f156d5928caf21da/3-0-ddi-m-4-door-at-namibu-orange-mica-front.png',
      alt: V_CROSS_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte456dec813c4503a/651668c0098aaa9f88ce49be/home-lineup-bg-v-cross-mobile.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt391cae8117816279/651668f5474d4d73914f465d/home-lineup-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: V_CROSS_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta194a0bce6272f50/651f73e62b65932b83c58368/hi-lander-4-door-3-0-ddi-m-dolomite-pearl-white.png',
      alt: PICKUP_4DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9f99dc2ea4f1adc2/65166a2623b7e78f5e6aa95c/home-lineup-bg-4-door-mobile.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blteee13e08dc626ff6/651669f1ec958dbcd8ea56b4/home-lineup-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: PICKUP_4DOOR_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    preTitle: '2-DOOR',
    title: PICKUP_2DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt56540ac740e15270/651f6b7b7bedef8ec594d891/spacecab-1-9-ddi-l-at-islay-gray-opaque-front.png',
      alt: PICKUP_2DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfdb10b89c9309abf/651a372d375d9858b19cd417/home-lineup-bg-2-door-mobile.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1742a8452ba21e70/651a3749f156d54cbbaf05da/home-lineup-bg-2-door.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: PICKUP_2DOOR_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: PICKUP_2DOOR_DATA.price,
  },
  {
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1198d797f268acbe/651f6c1f705ef3222048b515/spark-4x4-3-0-ddi-s-mt-bohemian-silver-metallic-front.png',
      alt: SPARK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc51540266564afd1/651669a623b7e7b9f46aa95a/home-lineup-bg-spark-mobile.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt180e8b8b245e62df/651669cccf50bf3f2afffa5c/home-lineup-bg-spark.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: SPARK_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    preTitle: 'X-SERIES',
    title: X_SERIES_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta320386b316dfe35/65a7f250c992b705a5b89fc7/x-series-front-speed-4-door-mt-bavarian-black-mica.png',
      alt: X_SERIES_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt93ed6b5cae0741f3/65a8e4281e1aed5c6bfd43d7/x-series-bg.jpg',
      alt: X_SERIES_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta4d162e54c237555/65a8e3f9d7d856eaecb4eb5b/x-series-bg-desktop.jpg',
      alt: X_SERIES_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: X_SERIES_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: X_SERIES_DATA.price,
    theme: 'dark',
  },
];

export const PRODUCT_LINEUP_DATA: IMy24LineupItem[] = [
  {
    preTitle: 'MU-X',
    title: 'มิว-เอ็กซ์',
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt95d5d1008250eead/66692ca146a0375d52a26414/mu-x-front-rs-eiger-grey.png',
      alt: MUX_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd2255779dd00e168/665996b75311e3c53bce84a8/mu-x-lineup-bg.jpg',
      alt: MUX_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc36c2913b3c62ba7/665996b744be248c94e20689/mu-x-lineup-bg-desktop.jpg',
      alt: MUX_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: '/isuzu-mu-x',
      color: 'button-gray',
      isMinWidth: true,
    },
    price: MUX_DATA.price,
  },
  {
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltab16cade4bd5daee/651f7880f156d5928caf21da/3-0-ddi-m-4-door-at-namibu-orange-mica-front.png',
      alt: V_CROSS_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6ef1a3411dc0d7f6/651665744f2fa8e5cb69a5ae/bg-v-cross-desktop.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6ef1a3411dc0d7f6/651665744f2fa8e5cb69a5ae/bg-v-cross-desktop.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: V_CROSS_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta194a0bce6272f50/651f73e62b65932b83c58368/hi-lander-4-door-3-0-ddi-m-dolomite-pearl-white.png',
      alt: PICKUP_4DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt26af7ebfa7390de6/651664a74d72e9a72384cf5d/bg-4-door-desktop.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt26af7ebfa7390de6/651664a74d72e9a72384cf5d/bg-4-door-desktop.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: PICKUP_4DOOR_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    preTitle: '2-DOOR',
    title: PICKUP_2DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt56540ac740e15270/651f6b7b7bedef8ec594d891/spacecab-1-9-ddi-l-at-islay-gray-opaque-front.png',
      alt: PICKUP_2DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt070c8494d1080e3c/651a3b3cf64f15c803b45911/bg-2-door-desktop.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt070c8494d1080e3c/651a3b3cf64f15c803b45911/bg-2-door-desktop.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: PICKUP_2DOOR_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: PICKUP_2DOOR_DATA.price,
  },
  {
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1198d797f268acbe/651f6c1f705ef3222048b515/spark-4x4-3-0-ddi-s-mt-bohemian-silver-metallic-front.png',
      alt: SPARK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt93f0049e0c1a5e85/6516654c8f04571e4212b636/bg-spark-desktop.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt93f0049e0c1a5e85/6516654c8f04571e4212b636/bg-spark-desktop.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: SPARK_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    preTitle: 'X-SERIES',
    title: X_SERIES_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta320386b316dfe35/65a7f250c992b705a5b89fc7/x-series-front-speed-4-door-mt-bavarian-black-mica.png',
      alt: X_SERIES_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2e85eaecb1d5dd93/65a8e3a9c1930ffcbff8ae39/x-series-bg.jpg',
      alt: X_SERIES_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2e85eaecb1d5dd93/65a8e3a9c1930ffcbff8ae39/x-series-bg.jpg',
      alt: X_SERIES_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: X_SERIES_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: X_SERIES_DATA.price,
  },
];
